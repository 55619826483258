/* GENERAL STYLES  */
footer {
    background-color: black;
    box-sizing: border-box;
    color: white;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
}

footer h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.625rem;
}

footer a {
    color: white;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.438rem;
    text-decoration: none;
}

footer p {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    justify-content: center;
    line-height: 1.063;
    margin-top: 0.5rem;
    text-align: center;
}

.footer__head {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
}

.footer__logo{
    height: 5.875rem;
    width: 12.875rem;
}

.footer__nav__link {
    display: inline-block;
}    

.footer__nav__link img{
    height: 3rem;
    width: 3rem;
}

.footer__catalog {
    display: flex;
    flex-direction: column;
    gap: 0.938rem;
    margin-bottom: 1rem;
}

.footer__catalog__info {
    align-items: center;
    margin-top: 0.625rem;
}

.footer__catalog__info__link {
    display: flex;
}

.footer__catalog__info__link img {
    height: 1.25rem;
    margin-right: 0.625rem;
    width: 1.25rem;
}

.footer__catalog__info__list{
    display: flex;
    flex-direction: column;
    gap: 0.313rem;
}

/* TABLET */
@media screen and (min-width: 700px){
    footer {
        /* padding-bottom: 2rem; */
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;

    }

    footer h3 {
        font-size: 1.25rem;
    }

    footer a {
        color: white;
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.438rem;
        text-decoration: none;
    }
    
    .footer__nav__link {
        margin-bottom: 1.3rem;
        margin-top: 1.3rem;
    }
}

/* Laptop */
@media screen and (min-width: 1200px){
    footer {
        padding-left: 6rem;
        padding-right: 6rem;
        padding-top: 2.5rem;
    }
    
    .footer__catalog {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer__catalog__info {
        width: 18rem;
    }

    .footer__catalog__info:nth-child(2) {
        margin-left: -2.188rem;
    }

    .footer__catalog__info:nth-child(3) {
        width: auto;
    }
} 

@media screen and (max-width: 200px) {
    footer {
        word-break: break-all;
    }
}

