.error__container {
    display: flex;
    background: white;
    flex-direction: column;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    align-items: center;
}

.error__container h1 {
    font-size: 9rem;
    color: #CE571C;
    margin-bottom: 1.5rem;
}

.error__container h3 {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 1.25rem;
}

.error__container p {
    width: 100%;
    font-size: 1rem;
}

.error__container a {
    color: #CE571C;
    word-wrap: break-word;
    text-decoration: none;
}

/* TABLET */
@media screen and (min-width: 700px) {
    .error__container {
        padding-bottom: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
    }
}

/* LAPTOP */
@media screen and (min-width: 1200px) {
    .error__container {
        padding-left: 6rem; 
        padding-right: 6rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}