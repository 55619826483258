nav {
    background-color: white;
    align-items: center;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    line-height: 19px;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
}

.whatsapp-link {
    display: none;
}

nav div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.nav__logo {
    cursor: pointer;
    justify-self: start;
    height: 5.875rem;
}

.nav__menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 25px;
    align-items: center;
    list-style: none;
    text-align: center;
    justify-content: end;
}

.nav-item {
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #2A3342;
    text-decoration: none;

}

.nav__item-active {
    font-weight: 600;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #CE571C;
}

.nav__button__whatsapp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5px 1rem;
    gap: 6px;
    width: auto;
    height: 41px;
    background: #CE571C;
    box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
    border-radius: 15px;
    flex: none;
    order: 1;
    flex-grow: 1;
    white-space: nowrap;

    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #EEF3FF;
    border: none;
}

.nav__button__whatsapp img {
    width: 24.98px;
    height: 25px;
}

.nav__menu-icons {
    display: none;
}

.fa-bars {
    color: #CE571C;
}

.fa-times {
    color: #CE571C;
}



@media screen and (min-width: 0px) and (max-width: 1200px) {
    nav{
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 1.5rem;
    }
    
    .nav__logo {
        width: 9.375rem;
        height: 5.875rem;
    }

    .nav__menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
        box-shadow: 0 8px 32px rgba(31, 38, 137, 0.37);
        backdrop-filter: blur(4px);
        border-radius: 10px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0px;
        left: -110%;
        align-items: center;
        padding: 80px 0 30px 0;
        transition: all 0.3s ease-in-out;
    }

    .nav__menu.active {
        left: 0%;
        transition: all 0.3s ease-in-out;
    }

    .nav__item {
        display: block;
        widows:  100%;
        padding: 2rem 0;
    }

    .nav__item-active {
        font-weight: 600;
        line-height: 19px;
        display: block;
        color: #CE571C;
    }

    .nav__menu-icons{
        display: block;
        z-index: 99;
    }

    .whatsapp-link {
        display: block;
        color: #CE571C;
    }
}


@media screen and (min-width: 700px) and (max-width: 1200px) {
    nav{
        padding-left: 3rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 3rem;
    }

    .nav__logo {
        width: 10.938rem;
    }
}

