.contact {
    display: flex;
    flex-direction: column;
    padding-bottom:1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
}

.contact__details {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.contact__details__title {
    color: #CE571C;
    font-size: 1.25rem; 
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    word-break: break-word;
}

.contact__details__link {
    align-items: center;
    color: black;
    cursor: default;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.3rem;
    padding-right: 0;
    text-decoration: none;
    width: auto;
}

.contact__details__link-icon {
    cursor: pointer;
    display: block;
    height: 1.7rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
    width: 1.7rem;
}

.contact__details__link-text{
    color: black;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2rem;
    margin-bottom: 0;
    width: 100%;
    width: auto;
    word-break: break-word;
}

iframe {
    border-radius: 2.5px;
    height: 50%;
    width: 100%;
    margin-bottom: 1rem;
}

/* TABLET */
@media screen and (min-width: 700px) {
    .contact__banner{
        height: 40%;
    }

    .contact {
        padding-bottom:2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
    }

    iframe {
        height: 100%;
    }
}

/* LAPTOP  */
@media screen and (min-width: 1200px) {
    .contact__banner{
        height: 50%;
    }

    .contact {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom:3rem;
        padding-left: 6rem;
        padding-right: 6rem;
        padding-top: 3rem;
    }

    .contact__details {
        flex-basis: 50%;
        margin-bottom: 3.5rem;
    }

    .contact__details__title {
        line-height: 1.75rem;
        margin-bottom: 0.75rem;

    }

    .contact__details__link {
        display: flex;
        margin-bottom: 0.5rem;
    }
    
    .contact__details__link-icon {
        margin-right: 0.75rem;
    }

    .contact__details__link-text{
        max-width: 80%;
        width: auto;
    }

    iframe {
        height: 20rem;
    }
}