.services {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;    
    font-size: 1.125rem;
}

.services__title {
    color: #CE571C;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    word-break: break-word;
}

.services p {
    letter-spacing: 2px;
    margin-bottom: 1rem;
}

.services__item {
    background-color: white;
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
}

.services__item__text {
    display: flex;
    color: #CE571C;
    gap: 7px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.93rem;
    word-break: break-word;
    align-items: center;
}

.services__item__text__title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    word-break: break-word;
}


.services__item__list {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.2rem;
    margin-bottom: 0.3rem;
    padding-left: 1.25rem;
    word-break: break-word;
}

.services__item__list::marker {
    margin: 0;

}

i {
    padding: 0;
    margin: 0;
}

/* TABLET */
@media screen and (min-width: 700px) {
    .services__banner{
        height: 40%;
    }

    .services {
        padding-bottom: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
    }

    .services__title {
        line-height: 1.75rem;
        margin-bottom: 1.75rem;
    }

    .services__item {
        padding: 2rem;
        margin-bottom: 2rem;
    }

    .services__item__text__title {
        line-height: 1.75rem;
    }

    .services__item__list {
        line-height: 1.45rem;
        margin-bottom: 0.5rem;
        padding-left: 1.5rem;
    }
}

/* DESKTOP */
@media screen and (min-width: 1200px) {
    .services__banner{
        height: 50%;
    }

    .services {
        padding-left: 6rem;
        padding-right: 6rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .services__title {
        margin-bottom: 1rem;
    }

    .services p {
        margin-bottom: 2rem;
    }

    .services__item {
        padding: 3rem;
        margin-bottom: 3rem;
    }

    .services__item__text {
        padding-left: 2rem;
        margin-left: 0;
        padding-left: 0;
    }

    .services__item__text__title {
        line-height: 1.75rem;
    }
}