ul {
    margin: 0;
    padding: 0;
}

.home__banner{
    background-repeat: no-repeat;
    height: 30%;
    background-position: 60% 10%;
    background-size: cover;
    background-image: url('./../../images/home.jpg');
}

.home {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
}

.home__intro {
    font-size: 1.125rem;
    line-height: 1.18rem;
    color: #000000;
    width: 100%;
}

.home__intro p {
    margin-bottom: 0.5rem;
    letter-spacing: 2px;
}

.home__intro p:last-child {
    margin-bottom: 0;
}

.home__services {
    margin-top: 3.75rem;
    margin-bottom: 3.43rem;
}


.home__services h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #CE571C;
    margin-bottom: 2.5rem;
}

.home__services__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.home__services__list__item {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.18rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home__services__list__item img {
    width: 6.25rem;
    height: 4.87rem;
    margin-bottom: 1.18rem;
}

.home__services__list__item span{
    font-size: 1.125rem;
}

/* TABLET */
@media screen and (min-width: 700px) {
    .home__banner{
        height: 40%;
    }

    .home {
        padding-bottom: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
    }

    .home__services {
        margin-bottom: 1.5rem;
    }

    .home__services__list__item {
        margin: 0;
    }
}

/* LAPTOP */
@media screen and (min-width: 1200px) {
    .home__banner{
        height: 50%;
    }

    .home {
        padding-left: 6rem;
        padding-right: 6rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    
}

@media screen and (max-width: 250px) {
    .home {
        word-break: break-all;
    }
}