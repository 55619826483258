.about__banner{
    background-repeat: no-repeat;
    height: 30%;
    background-position: 50% 40%;
    background-size: cover;
    background-image: url('./../../images/about.jpg');
}

.about {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
}

.about__office {
    margin-bottom: 1rem;
}

.about__office__text h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;

    color: #CE571C;
    margin-bottom: 0.5rem;
}

.about__office__text p {
    letter-spacing: 2px;
    font-size: 1.125rem;
    line-height: 1.18rem;
    color: #000000;
    margin-bottom: 0.5rem;
}

.about__office__text p:last-child {
    margin-bottom: 0;
}

.about__team h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;

    color: #CE571C;
    margin-bottom: 1rem;
}

.about__team__member {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.about__team__member__photo{
    width: 100%;
    height: 50%;
    margin-bottom: 1.56rem;
}

.about__team__member h3 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #CE571C;
    margin-left: 1rem;
    margin-right: 1rem;
}

.about__team__member h5 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.18rem;
    margin-bottom: 0.43rem;
    color: #000000;
    margin-left: 1rem;
    margin-right: 1rem;
}

.about__team__member p{
    font-size: 1.12rem;
    line-height: 1.19rem;
    margin-bottom: 1.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    letter-spacing: 2px;
}

.about__team__member ul {
    list-style: none;
    margin-left: 1rem;
    margin-right: 1rem;
}

/* TABLET */
@media screen and (min-width: 700px) {
    .about__banner{
        height: 40%;
    }

    .about {
        padding-bottom: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
    }

    .about__office__text {
        margin-bottom: 2.5rem;
    }

    .about__office__text h3 {    
        margin-bottom: 1.25rem;
    }

    .about__team h3 {
        margin-bottom: 1.25rem;
    }

    .about__team__member {
        margin-bottom: 3rem;
    }

    .about__team__member__photo {
        height: auto;
    }
}

@media screen and (min-width: 1200px) {
    .about__banner{
        height: 50%;
    }

    .about {
        padding-left: 6rem;
        padding-right: 6rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    
    .about__office__text {
        margin-bottom: 2rem;
    }

    .about__office__text h3 {    
        margin-bottom: 1.5rem;
    }

    .about__team h3 {
        margin-bottom: 1.25rem;
    }

    .about__team__member {
        display: flex;
        margin-bottom: 2rem;
        padding: 0;
    }

    .about__team__member__photo {
        margin: 0;
        width: 16rem;
        height: 19.625rem;
    }

    .about__team__member__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 3.063rem;
    }

    .about__team__member__info h3 {
        margin-bottom: 1.625rem;
    }

    .about__team__member__info h5 {
        margin-bottom: 1.3rem;
    }

    .about__team__member__info p {
        margin-bottom: 1.3rem;
        margin-right: 3.063rem;
    }

    .about__team__member__socials__item {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 200px) {
    .about {
        word-break: break-all;
    }
}